export default (props) => {
  const { item, idx, styles } = props;
  if (!item || !idx) return null;
  return (
    <div
      key={idx}
      className={styles['marker']}
      lat={item.value.location[0]}
      lng={item.value.location[1]}
    >
      <img src="/img/landing-pages/homeowners/map-marker.svg" />
    </div>
  );
};
