import { buildLoginUrl } from '@zorba-shared/core/Services/urlBuilderService';

export const getHeaderLings = (path) => [

  {
    title: 'Home',
    href: '/',
    isActive: (pathname) => pathname == '/',
  },
  {
    title: 'Asset Managers',
    href: '/asset-managers',
    isActive: (pathname) => pathname == '/asset-managers',
  },
  {
    title: 'Agents',
    href: '/agents',
    isActive: (pathname) => pathname == '/agents',
  },
  {
    title: 'Investors',
    href: '/investors',
    isActive: (pathname) => pathname == '/investors',
  },

  {
    title: 'Blog',
    href: 'https://blog.getzorba.com',
    isActive: (pathname) => false,
  },  
  {
    title: 'FAQ',
    href: '/faq',
    isActive: (pathname) => pathname.includes('/faq'),
  },
  {
    title: 'Our story',
    href: '/about-us',
    isActive: (pathname) => pathname.includes('/about-us'),
  },
];
