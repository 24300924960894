import classNames from 'classnames';
import { isMobile } from '@zorba-shared/client/Services/clientService';

import { PlacesAutoComplete } from '../../../../components/forms';
import { GOOGLE_API_KEY } from '../../../../services/googleMapsService';

import styles from '../../SellerLeadWizard.module.scss';

export default (props) => {
  const { params } = props;

  const getStaticMapUrl = () => {
    if (!params?.address) return;
    let url = 'https://maps.googleapis.com/maps/api/staticmap?';
    url += `zoom=13&size=600x160&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&key=${GOOGLE_API_KEY}`;
    url += `&markers=color:red%7Clabel:S%7C${params.address.formattedAddress}`;
    url += `&center=${params.address.formattedAddress}`;

    return url;
  };
  const setHeaderTextState = (state) => {
    if (!isMobile()) return
    
    if (state) {
      document.querySelector("#wizard-title").style.display = "block"
      document.querySelector("#wizard-desc").style.display = "block"
    }
    else {
      document.querySelector("#wizard-title").style.display = "none"
      document.querySelector("#wizard-desc").style.display = "none"
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className={classNames(styles.step, styles['property-fill'])}>
      <div className={classNames(styles['map-preview'], {[styles['open']]: !!params.address?.formattedAddress})}>
      <div style={{ borderRadius: 10, width: '600px',height: '160px', backgroundImage: `url('${getStaticMapUrl()}')`, backgroundSize: 'cover',  }} />
      </div>

      <div className={classNames(styles.row, styles['address-fields'])}>
        <PlacesAutoComplete
          clearInputOnSelect={false}
          clearInputOnBlur={false}
          showIcon={false}
          title=""
          fullWidth
          placeholder="Street address, city, state"
          onFocus={() => {setHeaderTextState(false)}}
          onBlur={() => {setHeaderTextState(true)}}
          onChange={(newVal) => {
            props.onChange('address', newVal);
            setHeaderTextState(true)
          }}
          allowFullAddress
          placesAutoCompleteStyle={styles['places-auto-complete']}
          initialInputText={params.address?.formattedAddress}
        />
      </div>
      <div className={styles.note}>
        <ul>
          <li>Free with no obligation to sell</li>
          <li>No need to worry about showings, repairs, or paperwork - we take care of it all for you</li>
          <li>You'll get to choose from multiple cash offers, so you can find the best one for you</li>
        </ul>
      </div>
    </div>
  );
};
