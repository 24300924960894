import { Layout as AntLayout } from 'antd';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initAnalytics } from '../../../services/analyticsService';
import Header from '../../../containers/layout/Header';
import {
  setInitialClientUrl,
  setTestMode,
  setUserSource,
} from '../../../services/userTrackingService';
import styles from './index.module.scss';
import { extendUrlSearchParams } from '../../../services/clientService';
import { ErrorBoundary } from '@highlight-run/react';
import { isDev } from '@zorba-shared/client/Services/clientService';
const LayoutComponent = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    initAnalytics()
    extendUrlSearchParams();
    setUserSource(router, dispatch);
    setTestMode();
    setInitialClientUrl();
  }, []);

  return (
    <ErrorBoundary showDialog={!isDev()}>
      <AntLayout className={styles['ant-layout']}>
        <Header pageName={props.pageName} />
        <div className={styles['layout-content']}>{props.children}</div>
      </AntLayout>
    </ErrorBoundary>
  );
};

export default LayoutComponent;
