/* eslint-disable no-restricted-syntax */
import { isDev } from '@zorba-shared/client/Services/clientService';
import { reportScreen as reportScreenGlobal } from '@zorba-shared/client/Services/analyticsService';
import * as Sentry from '@sentry/nextjs';
import Analytics from 'analytics';
import customerIo from '@analytics/customerio';
import ReactGA from "react-ga4";
import { isClientSide } from './clientService';
import BasicLogger from './logging/BasicLogger';
// import { H } from 'highlight.run';

let isInit = false;
let isCustomerIoInitialized = false;

let analytics;
const isDevMode = isDev()
const ampGetQueryParams = (qs) => {
  qs = qs.split('+').join(' ');

  const params = {};
  let tokens;
  const re = /\butm_([^=]+)=([^&]+)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const initAnalytics = async () => {
  const logger = new BasicLogger('initAnalytics');
  try {
    if (!isClientSide() || isDev()) return;

    const Amplitude = require('amplitude-js').default;

    ReactGA.initialize('AW-10803247092');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Zorba Home Page" });

    Amplitude.getInstance().init('01d39fea36f5130c476444536462355a', null, {
      // optional configuration options
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
    });
    analytics = Analytics({
      app: 'zorba',
      version: 1,
      plugins: [
        customerIo({
          siteId: 'b5b545d8741f02dcca74',
        }),
      ],
    });
    // window.analytics = analytics;
    // Populate the object to be sent to Amplitude
    const utmParams = ampGetQueryParams(document.location.search);
    const propertiesToReport = {};
    for (const param in utmParams) {
      propertiesToReport[`utm_${param}`] = utmParams[param];
    }
    if (Object.getOwnPropertyNames(propertiesToReport).length !== 0) {
      Amplitude.getInstance().setUserProperties(propertiesToReport);
    }

    // H.init('xdn544d0', {
    //   tracingOrigins: true,
    //   networkRecording: {
    //       enabled: true,
    //       recordHeadersAndBody: true
    //   },
    // });
  } catch (e) {
    logger.exception(e);
  }
};

export const reportConversion = async (
  sendTo = 'AW-10803247092/qT1RCMa33dIDEKLDv8wo'
) => {
  if (isDev()) return;
  if (!window.gtag) return;
  window.gtag('event', 'conversion', {
    send_to: sendTo,
    // value: amount,
    currency: 'USD',
    transaction_id: '',
  });
};

export const reportScreen = async (name) => {
  if (!isClientSide()) return;
  if (isDevMode) return;
  if (!isInit) {
    await initAnalytics();
    isInit = true;
  }
  reportScreenGlobal(name);
};

export const reportEvent = async (category, action, additionalParams) => {
  if (!isClientSide()) return;
  if (isDevMode) return;
  if (!isInit) {
    await initAnalytics();
    isInit = true;
  }
  const Amplitude = require('amplitude-js').default;
  // if (getLocalStorageItem(LOCAL_STORAGE_IGNORE_ANALYTICS)) return;
  ReactGA.event({
    category,
    action,
    ...additionalParams,
    currentUrl: window.location.href,
  });

  // If Customer IO was not initialized --> do not report these params
  if (isCustomerIoInitialized) {
    analytics.track(`${category}_${action}`, additionalParams);
  }
  Amplitude.getInstance().logEvent(`${category}_${action}`, additionalParams);
  console.log(`${category}_${action}`)
};

export const setUserProperty = async (key, value, sellerLeadId) => {
  if (isDevMode) return;
  if (!isClientSide()) return;
  const Amplitude = require('amplitude-js').default;
  ReactGA.set({ [key]: value });
  Amplitude.getInstance().setUserProperties({ [key]: value });

  // Identify the user
  if (sellerLeadId) {
    await analytics.identify(sellerLeadId);
    isCustomerIoInitialized = true;
    await analytics.identify(sellerLeadId, { [key]: value, userType: 'seller' });
  }
};

export const setUserProperties = async (sellerLead) => {
  if (isDevMode) return;
  if (!isClientSide()) return;
  // if (window?.__insp && sellerLead) {
  //   if (sellerLead.email) {
  //     window.__insp.push(['identify', sellerLead._id]);
  //   }

  //   window.__insp.push([
  //     'tagSession',
  //     _.pick(sellerLead, ['firstName', 'lastName', 'email']),
  //   ]);
  // }

  if (sellerLead?._id) {
    const reportedParams = _.pick(sellerLead, [
      'firstName',
      'lastName',
      'email',
      'name',
      'status',
      'source',
      // 'phoneNumber',
    ]);
    
    // Make sure we don't accidentally remove stuff
    Object.keys(reportedParams).map(prop => {
      if (reportedParams[prop] === null || reportedParams[prop] === undefined) {
        delete reportedParams[prop]
      }
    })
    if (sellerLead.phoneNumber) {
      reportedParams.phone = sellerLead.phoneNumber;
    }
    if (sellerLead.address?.formattedAddress) {
      reportedParams.address = sellerLead.address.formattedAddress;
      reportedParams.addressObject = sellerLead.address;
    }


    reportedParams.userType = 'seller';
    await analytics.identify(sellerLead._id);
    isCustomerIoInitialized = true;
    await analytics.identify(sellerLead._id, reportedParams);
    
    try {
      Sentry.setContext('character', {
        userId: sellerLead._id,
        firstName: sellerLead.firstName,
        lastName: sellerLead.lastName,
        phoneNumber: sellerLead.phoneNumber,
        email: sellerLead.email,
        address: sellerLead.address.formattedAddress,
      });
    } catch (e) {}
  }
};
