import styles from '../../SellerLeadWizard.module.scss';
import classNames from 'classnames';
import { ZorbaInput } from '../../../../components/forms';
import { ZorbaRadioGroup } from '../../../../components/forms';
import { CONDITION_OPTIONS } from '../../constants';
export default (props) => {
  const { params } = props;

  return (
    <div className={classNames(styles['step'], styles['condition'])}>
      <ZorbaRadioGroup
        options={CONDITION_OPTIONS}
        onChange={(newVal) => props.onChange('condition', newVal)}
        value={params.condition}
        direction="column"
      />
    </div>
  );
};
