import { useState } from 'react';
import classNames from 'classnames';
import parsePhoneNumber, { isPossiblePhoneNumber as checkIsPossiblePhoneNumber } from 'libphonenumber-js';

import { ZorbaInput } from '../../../../components/forms';
import { disableSpecialCharacters, formatPhoneNumber } from '../../utils';
import { defaultPhoneNumberCountry } from '../../constants';
import styles from '../../SellerLeadWizard.module.scss';
import inputStyles from '../../../../components/forms/ZorbaInput/ZorbaInput.module.scss';

export default (props) => {
  const { params } = props;
  const isPossiblePhoneNumber = checkIsPossiblePhoneNumber(params.phoneNumber || '', defaultPhoneNumberCountry);
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  const isPhoneNumberInvalid = Boolean(!isPossiblePhoneNumber && !isPhoneNumberFocused && params.phoneNumber);

  const handlePhoneInputChange = (event) => {
    const parsedPhoneNumber = parsePhoneNumber(event.target.value, defaultPhoneNumberCountry)?.nationalNumber;
    const formattedPhoneNumber = formatPhoneNumber(parsedPhoneNumber);
    props.onChange('phoneNumber', formattedPhoneNumber || event.target.value.slice(0,14));
  }

  return (
    <div className={classNames(styles.step, styles['contact-details'])}>
      <div className={styles.inner}>
        <ZorbaInput
          placeholder="Email address"
          onChange={(e) => props.onChange('email', (e.target.value || "").trim())}
          value={params.email}
        />
        <ZorbaInput
          // type='tel'
          placeholder="(XXX) XXX-XXXX"
          onChange={handlePhoneInputChange}
          value={params.phoneNumber}
          prefix={'+1'}
          onKeyPress={disableSpecialCharacters}
          onBlur={() => setIsPhoneNumberFocused(false)}
          onFocus={() => setIsPhoneNumberFocused(true)}
          className={classNames(inputStyles['phone-number-input'], { [inputStyles['input-invalid']]: isPhoneNumberInvalid })}
        />
        {isPhoneNumberInvalid && <span className={styles['phone-input-error']}>Please provide a valid US phone number.</span>}
      </div>
    </div>
  );
};
