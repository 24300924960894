import { Radio } from 'antd';
import styles from './ZorbaRadioGroup.module.scss';
import classNames from 'classnames';

export default (props) => {
  const { options = [], value, direction = 'row' } = props;

  return (
    <div
      className={classNames(
        styles['zorba-radio-group'],
        styles[`direction-${direction}`]
      )}
    >
      <Radio.Group
        options={options}
        buttonStyle="solid"
        onChange={(e) => props.onChange(e.target.value)}
        value={value}
      />
    </div>
  );
};
