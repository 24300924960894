/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/nextjs';
import { isDev } from '@zorba-shared/client/Services/clientService';

export default class BasicLogger {
  constructor(name) {
    this.name = name;
  }

  getPrefix() {
    return `[${this.getTime()}][${this.serviceName}]`;
  }

  getTime() {
    return new Date().toLocaleString().replace(',', ' |');
  }

  exception(e) {
    if (isDev()) {
      console.error(e)
    } else {
      Sentry.captureException(e);
    }
    
  }

  info(msg) {
    const printedMsg = typeof msg === 'string' ? msg : JSON.stringify(msg);
    console.log(`${this.getPrefix()} ${printedMsg}`);
  }
}
