import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getHeaderLings } from "./constants";
import styles from './HeaderLinks.module.scss';


export default () => {
  const router = useRouter()
  const currentPathname = router.pathname;
  const headerLinks = getHeaderLings(currentPathname);

  return (
    <div className={styles["header-links"]}>
      {headerLinks.map((linkObj, idx) => {
        return (
          <span key={idx} className={linkObj.isActive(currentPathname) ? 'header-link-active' : ''}>
          {linkObj.href.startsWith("http") ? <Link
           key={idx}
            className={classNames(styles["link-item"])}
            href={linkObj.href}
          >
            <a>
            {linkObj.title}
            </a>
          </Link> : <a 
           key={idx}
           className={classNames(styles["link-item"])}
            href={linkObj.href}>
          {linkObj.title}
          </a>}
          </span>
        )
      }
      
      )}
    </div>
  );
};
