import {
  getCookie,
  getQueryParam,
  removeCookie,
  setCookie,
} from '@zorba-shared/client/Services/clientService';
import { setUserSource as setUserSourceAction } from '../reducers/Auth/actions';

const COOKIE_NAME_USER_SOURCE = 'user_source';
const COOKIE_NAME_TEST_MODE = 'test_mode';
const COOKIE_NAME_INITIAL_CLIENT_URL = 'initial_client_url';

export const setUserSource = (router, dispatch) => {
  if (!router) return;

  const sourceCookieValue = getCookie(COOKIE_NAME_USER_SOURCE);
  const sourceQueryValue = router.query?.utm_source;
  if (!sourceCookieValue && sourceQueryValue) {
    setCookie(COOKIE_NAME_USER_SOURCE, sourceQueryValue);
    dispatch(setUserSourceAction(sourceQueryValue));
  }
};

export const getUserSource = () => getCookie(COOKIE_NAME_USER_SOURCE);

export const isTestMode = () => getCookie(COOKIE_NAME_TEST_MODE);

export const setTestMode = () => {
  const isTestMode = getQueryParam(COOKIE_NAME_TEST_MODE);
  if (!isTestMode) return;
  setCookie(COOKIE_NAME_TEST_MODE, isTestMode);
  alert('Welcome to Test Mode');
};

export const setInitialClientUrl = () => {
  const clientUrl = window.location.href;
  const clientUrlCookieValue = getCookie(COOKIE_NAME_INITIAL_CLIENT_URL);
  if (!clientUrlCookieValue) {
    setCookie(COOKIE_NAME_INITIAL_CLIENT_URL, clientUrl);
  }
};

export const getInitialClientUrl = () =>
  getCookie(COOKIE_NAME_INITIAL_CLIENT_URL);

export const getQueryParamsAsObj = (location = window.location) => {
  const search = location.search.substring(1);
  if (!search) return JSON.stringify({});

  return JSON.stringify(
    JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    )
  );
};