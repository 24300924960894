import classNames from 'classnames';
import { Checkbox } from 'antd';
import styles from '../../SellerLeadWizard.module.scss';
import { ZorbaInput, ZorbaRadioGroup } from '../../../../components/forms';
import { HOA_OPTIONS, HOA_PERIOD_OPTIONS } from '../../constants';

export default (props) => {
  const { params } = props;

  return (
    <div className={classNames(styles.step, styles.hoa)}>
      <ZorbaRadioGroup
        options={HOA_OPTIONS}
        onChange={(newVal) => props.onChange('hoaQuestion', newVal)}
        value={params.hoaQuestion}
        direction="column"
      />

      {params.hoaQuestion && (
        <div className={styles['hoa-options']}>
          {/* PERIOD */}
          <div className={styles['hoa-question']}>
            <h3>How frequently are HOA fees due?</h3>
            <ZorbaRadioGroup
              options={HOA_PERIOD_OPTIONS}
              onChange={(newVal) => props.onChange('hoaPeriod', newVal)}
              value={params.hoaPeriod}
              direction="column"
            />
          </div>
          {/* FEE */}
          <div className={styles['hoa-question']}>
            <h3>What is the HOA fee?</h3>
            <ZorbaInput
              placeholder="HOA fee ($)"
              type="number"
              value={params.hoaFee}
              onChange={(newVal) => props.onChange('hoaFee', newVal)}
              symbol="$"
              className={styles['zorba-input']}
            />
          </div>
          <div className={styles['hoa-question']}>
            <h3>Does your home belong to any of these types of communities?</h3>
            <Checkbox.Group
              style={{
                width: '100%',
              }}
              onChange={(newVal) => props.onChange('hoaOptions', newVal)}
              defaultValue={params.hoaOptions}
            >
              <ul>
                <li>
                  <Checkbox value="age_restricted_community">
                    Age restricted community
                  </Checkbox>
                </li>
                <li>
                  <Checkbox value="gated_community">Gated community</Checkbox>
                </li>
                <li>
                  <Checkbox value="no_restrictions">None of the above</Checkbox>
                </li>
              </ul>
            </Checkbox.Group>
          </div>
        </div>
      )}
    </div>
  );
};
