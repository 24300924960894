import { isEmailValid } from '@zorba-shared/core/Services/textService';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import {
  SELLER_LEAD_STATUS_INITIAL,
  SELLER_LEAD_QUESTIONNAIRE,
  SELLER_LEAD_PHOTOS,
  SELLER_LEAD_APPOINTMENT,
  SELLER_LEAD_BANNED,
  SELLER_LEAD_AGREEMENT,
  SELLER_LEAD_SUCCESS,
} from '@zorba-shared/core/Constants/leads';
import PropertyTypeStep from './Components/PropertyTypeStep';
import FullNameStep from './Components/FullNameStep';
import PropertyFillStep from './Components/PropertyFillStep';
import PropertyVerificationStep from './Components/PropertyVerificationStep';
import TimelineStep from './Components/TimelineStep';
import ConditionStep from './Components/ConditionStep';
import AskingPriceStep from './Components/AskingPriceStep';
import ReasonStep from './Components/ReasonStep';
import MarketingSourceStep from './Components/MarketingSourceStep';
import ContactDetailsStep from './Components/ContactDetailsStep';
import LoadingStep from './Components/LoadingStep';
import ListedStep from './Components/ListedStep';
import HOAStep from './Components/HOAStep';
import { MIN_ASKING_PRICE } from '../../constants/sellerLead';

export const STEP_KEY_LEAD_NAME = 'name';
export const STEP_KEY_PROPERTY_TYPE = 'property-type';
export const STEP_KEY_PROEPRTY_FILL = 'address';
export const STEP_KEY_PROPERTY_VERIFICATION = 'verify';

export const STEP_KEY_SELL_TIMELINE = 'timeline';
export const STEP_KEY_CONDITION = 'condition';

export const STEP_KEY_ASKING_PRICE = 'asking-price';
export const STEP_KEY_REASON = 'reason';
export const STEP_KEY_MARKETING_SOURCE = 'marketing-source';
export const STEP_KEY_LISTED_WITH_AGENT = 'listed';
export const STEP_KEY_CONTACT_DETAILS = 'contact';
export const STEP_KEY_LOADING = 'loading';
export const STEP_KEY_SUCCESS = 'success';
export const STEP_KEY_HOA = 'hoa';
export const DEFAULT_WIZARD_STEPS = [
  STEP_KEY_PROEPRTY_FILL,
  // STEP_KEY_PROPERTY_VERIFICATION,
  STEP_KEY_LEAD_NAME,
  STEP_KEY_PROPERTY_TYPE,
  STEP_KEY_SELL_TIMELINE,
  STEP_KEY_CONDITION,
  STEP_KEY_ASKING_PRICE,
  STEP_KEY_REASON,
  STEP_KEY_MARKETING_SOURCE,
  STEP_KEY_HOA,
  STEP_KEY_LISTED_WITH_AGENT,
  STEP_KEY_CONTACT_DETAILS,
  STEP_KEY_LOADING,
  STEP_KEY_SUCCESS,
];

const INITIAL_STEP_DATA = {
  getTitle: (props, didUserFillAddress) =>
    `What are the best cash offers you can get on your house today? <br/> `,
  getDescription: (props, didUserFillAddress) =>
    `Receive offers instantly - it's quick and easy!<br/>What's your property address?`,
  getComponent: (props) => <PropertyFillStep {...props} />,
  params: ['address'],
  nextBtnText: 'Get my free offers',
  isValid: ({ address }) => !!address?.formattedAddress,
  hideBackBtn: true,
};

export const DEFAULT_WIZARD_DATA = {
  undefined: INITIAL_STEP_DATA,
  [STEP_KEY_LEAD_NAME]: {
    getTitle: (props) => `Who should we send the offers to?`,
    getDescription: (props) => 'Please enter your name below',
    getComponent: (props) => <FullNameStep {...props} />,
    nextBtnText: `Let's do this`,
    isValid: ({ firstName, lastName }) =>
      firstName?.length > 1 && lastName?.length > 1,
    params: ['firstName', 'lastName', 'source', 'address'],
  },
  [STEP_KEY_PROEPRTY_FILL]: INITIAL_STEP_DATA,
  [STEP_KEY_PROPERTY_VERIFICATION]: {
    getTitle: (params) => `Thanks!<br/>Did we get it right?`,
    getComponent: (props) => <PropertyVerificationStep {...props} />,
    params: [],
    nextBtnText: 'Yes',
    backBtnText: `No, let's try again`,
    isValid: () => true,
  },
  [STEP_KEY_PROPERTY_TYPE]: {
    getTitle: (props) => 'What is your property type?',
    getComponent: (props) => <PropertyTypeStep {...props} />,
    params: ['propertyType'],
    isValid: ({ propertyType }) => !!propertyType,
  },
  [STEP_KEY_CONDITION]: {
    getTitle: (props) => 'What is the condition of your property?',
    getComponent: (props) => <ConditionStep {...props} />,
    params: ['condition'],
    isValid: ({ condition }) => !!condition,
  },
  [STEP_KEY_ASKING_PRICE]: {
    getTitle: (props) => 'How much would you say your house is worth?',
    getComponent: (props) => <AskingPriceStep {...props} />,
    params: ['askingPrice'],
    isValid: ({ askingPrice }) =>
      askingPrice !== undefined && askingPrice >= MIN_ASKING_PRICE,
  },
  [STEP_KEY_CONTACT_DETAILS]: {
    getTitle: (props) => 'Where should we send your offers?',
    getDescription: (props) => ' ',
    getComponent: (props) => <ContactDetailsStep {...props} />,
    params: ['phoneNumber', 'email'],
    isValid: ({ phoneNumber, email }) =>
      isEmailValid(email) &&
      isPossiblePhoneNumber(phoneNumber, defaultPhoneNumberCountry),
  },
  [STEP_KEY_SELL_TIMELINE]: {
    getTitle: (props) => 'How soon are you looking to sell?',
    getComponent: (props) => <TimelineStep {...props} />,
    params: ['timeline'],
    isValid: ({ timeline }) => !!timeline,
  },
  [STEP_KEY_REASON]: {
    getTitle: (props) => 'Why are you thinking of selling?',
    getComponent: (props) => <ReasonStep {...props} />,
    params: ['reason'],
    isValid: ({ reason }) => !!reason,
  },
  [STEP_KEY_MARKETING_SOURCE]: {
    getTitle: (props) => 'How did you hear about us?',
    getComponent: (props) => <MarketingSourceStep {...props} />,
    params: ['marketingSource'],
    isValid: ({ marketingSource }) => !!marketingSource,
  },
  [STEP_KEY_LISTED_WITH_AGENT]: {
    getTitle: (props) => 'Are you currently working with a real estate agent?',
    getComponent: (props) => <ListedStep {...props} />,
    params: ['listedWithAgentQuestion'],
    isValid: ({ listedWithAgentQuestion }) =>
      listedWithAgentQuestion !== undefined,
  },
  [STEP_KEY_HOA]: {
    getTitle: (props) =>
      'Is your home part of a homeowner’s association (HOA)?',
    getComponent: (props) => <HOAStep {...props} />,
    params: ['hoaQuestion', 'hoaOptions', 'hoaFee'],
    isValid: (props) => {
      if (props.hoaQuestion) {
        return (
          props.hoaFee !== undefined &&
          props.hoaOptions?.length &&
          props.hoaPeriod
        );
      }
      return props.hoaQuestion !== undefined;
    },
  },
  [STEP_KEY_LOADING]: {
    getTitle: (props) => 'Analyzing your property...',
    getComponent: (props) => <LoadingStep {...props} />,
    isValid: (props) => true,
    hideFooter: true,
    hideBackBtn: true,
  },
};

export const DEFAULT_PARAMS = {
  // lead-name
  firstName: '',
  lastName: '',
  // property-fill
  address: undefined,
  propertyType: undefined,
  phoneNumber: '',
  email: '',
  hoaFee: undefined,
  // Step 3
};

export const PROPERTY_TYPES = {
  single_family_home: 'Single-Family Home',
  multi_family_home: 'Multi-Family Home',
  condo: 'Condomium',
  townhouse: 'Townhouse',
  lot: 'Vacant Lot',
  mobile_home: 'Mobile Home',
  other: 'Other',
};

export const PROPERTY_TYPE_OPTIONS = Object.keys(PROPERTY_TYPES).map((k) => ({
  label: PROPERTY_TYPES[k],
  value: k,
}));

export const TIMELINE_OPTIONS = [
  { label: 'As soon as possible', value: 'asap' },
  { label: '1-3 months', value: '1_3_months' },
  { label: '3-6 months', value: '3_6_months' },
  { label: '6-12 months', value: '6_12_months' },
  { label: '12 months or more', value: '12_months' },
];

export const CONDITION_OPTIONS = [
  { label: 'Remodeled', value: 'nothing' },
  { label: 'Needs a little work', value: 'little_work' },
  { label: 'Needs significant work', value: 'significant_work' },
  { label: 'Tear down', value: 'teardown' },
];

export const REASON_OPTIONS = [
  { label: `I’m looking to buy a different home`, value: 'looking' },
  { label: 'This is a second home', value: 'second_home' },
  { label: 'This is an investment property', value: 'investment' },
  {
    label: 'I’m in the middle of a financial or life event',
    value: 'distress',
  },
  { label: 'This is an inherited property', value: 'inheritance' },
  { label: 'Other', value: 'Other' },
];

export const MARKETING_SOURCE_OPTIONS = [
  { label: 'Television', value: 'tv' },
  { label: 'Social media', value: 'social' },
  { label: 'Radio', value: 'radio' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'Google search', value: 'google' },
  { label: 'Friend or family', value: 'friend_or_family' },
  { label: 'Mailer', value: 'mailer' },
  { label: 'Other', value: 'other' },
];

export const LISTED_WITH_AGENT_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const HOA_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const HOA_PERIOD_OPTIONS = [
  { label: 'Monthly', value: 'month' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Annualy', value: 'annual' },
];

export const STEP_KEY_BY_STATUS = {
  [SELLER_LEAD_STATUS_INITIAL]: DEFAULT_WIZARD_STEPS[0],
  agreement_requested: STEP_KEY_SUCCESS, // Legacy step, should be removed
  agreement_signed: STEP_KEY_SUCCESS, // Legacy step, should be removed
  contact: STEP_KEY_SUCCESS, // Legacy step, should be removed
  [SELLER_LEAD_QUESTIONNAIRE]: STEP_KEY_SUCCESS,
  [SELLER_LEAD_PHOTOS]: STEP_KEY_SUCCESS,
  [SELLER_LEAD_APPOINTMENT]: STEP_KEY_SUCCESS,
  [SELLER_LEAD_BANNED]: STEP_KEY_SUCCESS,
  [SELLER_LEAD_AGREEMENT]: STEP_KEY_SUCCESS,
  [SELLER_LEAD_SUCCESS]: STEP_KEY_SUCCESS,
};

export const defaultPhoneNumberCountry = 'US';
