import { AutoComplete as AntAutoComplete, Form, Input } from "antd";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
// import "./index.scss";
import styles from './index.module.scss'

const AutoComplete = (props) => {
  const {
    label,
    invalid,
    className,
    itemClassName = "",
    inputPrefix,
    success,
  } = props;
  const itemProps = _.pick(props, ["label", "help", "validateStatus"]);
  const autoCompleteProps = _.pick(props, [
    "disabled",
    "loading",
    "placeholder",
    "value",
    "options",
    "onSelect",
    "onBlur", "onFocus",
    "autoComplete",
    "onFocus",
  ]);

  const autoComplete = (
    <AntAutoComplete
      {...autoCompleteProps}
      className={classNames(styles["auto-complete"], {
        [className]: !!className,
        [`${styles["has-prefix"]}`]: !!inputPrefix,
      })}
    >
      <Input
        allowClear={props.allowClear}
        prefix={inputPrefix}
        onFocus={(event) => {
          if (event.target.autocomplete) {
            event.target.autocomplete = "off";
          }
        }}
        suffix={success ? <i className="fas fa-check-circle" /> : null}
        onChange={(e) =>
          props.onInputChange && props.onInputChange(e.target.value)
        }
      />
    </AntAutoComplete>
  );
  return label ? (
    <Form.Item
      {...itemProps}
      className={classNames(styles["auto-complete-item"],styles[`${itemClassName || ""}`])}
      validateStatus={props.validateStatus || (invalid ? "error" : "success")}
    >
      {autoComplete}
    </Form.Item>
  ) : (
    autoComplete
  );
};

AutoComplete.defaultProps = {
  loading: false,
  options: [],
  placeholder: "",
  includeFormItem: false,
  className: "",
  invalid: false,
  title: "",
  help: "",
  autoComplete: "off",
};
AutoComplete.propTypes = {
  loading: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  includeFormItem: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  invalid: PropTypes.bool,
  help: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default AutoComplete;
