import ModalReducer from './Modal';
import SellerWizardReducer from './SellerWizard';
import AuthReducer from './Auth';
import TranslationReducer from './Translation'

export default {
  ModalReducer,
  SellerWizardReducer,
  AuthReducer,
  TranslationReducer,
}


