import styles from '../../SellerLeadWizard.module.scss';
import classNames from 'classnames';
import { ZorbaInput } from '../../../../components/forms';
import { ZorbaRadioGroup } from '../../../../components/forms';
import { MARKETING_SOURCE_OPTIONS } from '../../constants';
export default (props) => {
  const { params } = props;

  return (
    <div className={classNames(styles['step'], styles['marketing-source'])}>
      <ZorbaRadioGroup
        options={MARKETING_SOURCE_OPTIONS}
        onChange={(newVal) => props.onChange('marketingSource', newVal)}
        value={params.marketingSource}
      />
    </div>
  );
};
