import classNames from 'classnames';
import styles from '../../SellerLeadWizard.module.scss';
import { ZorbaInput, ZorbaRadioGroup } from '../../../../components/forms';
import { CONDITION_OPTIONS } from '../../constants';

export default (props) => {
  const { params } = props;
  const onChange = (key, val) => {
    // props.onChange(key, Math.max(val, 1000));
    props.onChange(key, val);
  };
  return (
    <div className={classNames(styles.step, styles['asking-price'])}>
      <div className={styles.inner}>
        <ZorbaInput
          placeholder="Price ($)"
          type="number"
          value={params.askingPrice}
          onChange={(newVal) => onChange('askingPrice', newVal)}
          symbol="$"
          className={styles['zorba-input']}
        />
      </div>
      <div className={styles.inner}>
        <div className={styles['bubble-wrapper']}>
          <img src="/img/offer-wizard/donna-2.png" />
          <div className={styles.note}>
          Our algorithm will use this and other data sets to evaluate fair market value - what your home looks like to prospective buyers compared to other homes in the area.
          </div>
        </div>
      </div>
    </div>
  );
};
