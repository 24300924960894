import {
  getPlaceById,
  getPlacesAutoComplete
} from "@zorba-shared/client/Services/googlePlacesService";
import classNames from 'classnames';
import { useEffect, useState } from "react";
import { ZorbaInput } from "..";
import { isMobile } from '@zorba-shared/client/Services/clientService';
import AutoComplete from "../AutoComplete";
import styles from './index.module.scss';
import {hideKeyboard} from '../../../services/clientService'
const PlacesAutoComplete = (props) => {
  const {
    placeholder = "Miami, FL",
    clearInputOnSelect = true,
    clearInputOnBlur = true,
    title = "Property address",
    initialInputText = "",
    initialUnitText = "",
    allowFullAddress,
    help,
    className = "",
    itemClassName = "",
    showUnit = false,
    disabled,
    autoSelectMatchingItem,
    showIcon = true,
  } = props;

  const [address, setAddress] = useState(initialInputText);
  const [unit, setUnit] = useState(initialUnitText);
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  /**
   * Set the default unit
   */
  useEffect(() => {
    if (
      props.initialUnitText !== undefined &&
      props.initialUnitText !== address &&
      !clearInputOnSelect
    ) {
      setAddress(initialUnitText);
    }
  }, [initialUnitText]);

  /**
   * Set the default address
   */
  useEffect(() => {
    if (
      props.initialInputText !== undefined &&
      props.initialInputText !== address &&
      !clearInputOnSelect
    ) {
      setAddress(initialInputText);
    }
  }, [initialInputText]);

  useEffect(() => {
    if (isSelected) return;
    const timeOutId = setTimeout(async () => {
      if (
        address &&
        ![
          props.selectedPlace?.data?.formattedAddress,
          initialInputText,
        ].includes(address)
      ) {
        setLoading(true);
        const newSuggestions = await getPlacesAutoComplete(
          address,
          allowFullAddress
        );

        // If there's one result we auto select it
        if (autoSelectMatchingItem && newSuggestions.length === 1) {
          onAutoCompleteSelect("", { place: newSuggestions[0] });
          setSuggestions([]);
        } else {
          setSuggestions(newSuggestions || []);
        }
        setLoading(false);
      } else {
        setSuggestions([]);
      }
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [address, isSelected]);

  const onAutoCompleteSelect = async (value, place) => {
    setLoading(true);
    setAddress(typeof value === "string" ? value : place.place.description);
    const res = await getPlaceById(place.place.place_id);
    const newSelectedPlace = res?.data;
    setIsSelected(true);
    setAddress(newSelectedPlace?.formattedAddress);
    setLoading(false);
    props.onChange && props.onChange(newSelectedPlace);
    
    if (clearInputOnSelect) {
      setAddress("");
      setSuggestions([]);
    }
    hideKeyboard()
  };

  const onUnitChange = (e) => {
    setUnit(e.target.value);
    props.onUnitChange && props.onUnitChange(e.target.value);
  };

  const onInputChange = async (query) => {
    if (query === address) return;
    setAddress(query);
    setIsSelected(false);
    if (!query) {
      props.onChange && props.onChange();
    }
    if (query?.length > 0 && props.onFocus) {
      props.onFocus();
    }
  };
  const options = suggestions.map((x) => ({
    key: x.place_id,
    place: x,
    value: (
      <div key={x.place_id} className={styles["places-auto-complete-value-wrapper"]}>
        <div className={styles["places-auto-complete-value-left"]}>{x.description}</div>
      </div>
    ),
  }));

  return (
    <div className={classNames(styles["places-auto-complete"],props.placesAutoCompleteStyle)}>
      <AutoComplete
        disabled={disabled}
        label={title}
        className={className}
        itemClassName={itemClassName}
        loading={loading}
        placeholder={placeholder}
        value={address}
        allowClear={props.allowClear}
        help={help}
        options={options}
        onSelect={onAutoCompleteSelect}
        onInputChange={(q) => onInputChange(q)}
        validateStatus={props.validateStatus}

        onBlur={() => {
          if (clearInputOnBlur) {
            setAddress("");
            setSuggestions([]);
          }
        }}
        inputPrefix={showIcon && <i className="fas fa-map-marker-alt" />}
        success={!loading && props.valid}
      />
      {showUnit && (
        <div className={styles["unit-number"]}>
          <ZorbaInput
            className={styles["unit-input"]}
            placeholder="Unit# (Optional)"
            onChange={onUnitChange}
            value={unit}
          />
        </div>
      )}
    </div>
  );
};
export default PlacesAutoComplete;