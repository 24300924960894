import styles from '../../SellerLeadWizard.module.scss';
import classNames from 'classnames';
import { ZorbaInput } from '../../../../components/forms';
import { ZorbaRadioGroup } from '../../../../components/forms';
import { PROPERTY_TYPE_OPTIONS, TIMELINE_OPTIONS } from '../../constants';
export default (props) => {
  const { params } = props;

  return (
    <div className={classNames(styles['step'], styles['timeline'])}>
      <ZorbaRadioGroup
        options={TIMELINE_OPTIONS}
        onChange={(newVal) => props.onChange('timeline', newVal)}
        value={params.timeline}
        direction="column"
      />
    </div>
  );
};
