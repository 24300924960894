import styles from '../../SellerLeadWizard.module.scss';
import classNames from 'classnames';
import { ZorbaInput } from '../../../../components/forms';
import { ZorbaRadioGroup } from '../../../../components/forms';
import { LISTED_WITH_AGENT_OPTIONS } from '../../constants';
export default (props) => {
  const { params } = props;

  return (
    <div className={classNames(styles['step'], styles['marketing-source'])}>
      <ZorbaRadioGroup
        options={LISTED_WITH_AGENT_OPTIONS}
        onChange={(newVal) => props.onChange('listedWithAgentQuestion', newVal)}
        value={params.listedWithAgentQuestion} direction="column"
      />
    </div>
  );
};
