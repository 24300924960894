import { Button } from 'antd';
import classNames from 'classnames';
// import "./index.scss";
import styles from './ZorbaButton.module.scss';

export default (props) => {
  const {
    variant,
    className,
    rounded = false,
    fullWidth = true,
    children,
    href,
    ...inheritedProps
  } = props;

  return (
    <Button
      {...inheritedProps}
      href={props.href}
      className={classNames(styles['zorba-button'], {
        [className]: className,
        [styles[variant]]: variant,
        [styles['btn-rounded']]: rounded,
        [styles['full-width']]: fullWidth,
      })}
    >
      {children}
    </Button>
  );
};
