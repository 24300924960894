import styles from '../../SellerLeadWizard.module.scss';
import classNames from 'classnames';
import { PlacesAutoComplete, ZorbaInput } from '../../../../components/forms';
import { GOOGLE_API_KEY } from '../../../../services/googleMapsService';
import GoogleMapReact from 'google-map-react';
import MapMarker from '../../../HomeownersOnePager/Components/OffmarketTrends/Components/MapMarker';

export default (props) => {
  const { params, onChange } = props;
  const { address } = params;

  if (!address) return null;
  return (
    <div
      className={classNames(styles['step'], styles['property-verification'])}
    >
      {address?.location?.length === 2 && (
        <div className={styles['map-wrapper']}>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            defaultZoom={14}
            maxZoom={14}
            draggable={false}
            center={{
              lat: address.location[0],
              lng: address.location[1],
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              if (map) {
                map.maxZoom = 14;
                window.mapRef = map;
                window.mapsRef = maps;

                // fitBounds(map, maps);
              }
            }}
          >
            <MapMarker idx={1} item={{ value: address }} styles={styles} />
          </GoogleMapReact>
        </div>
      )}
      <div className={styles['address-text']}>
        {`${params.address?.formattedAddress}${
          params.unit ? ` ${params.unit}` : ''
        }`}
      </div>
      {/* <ZorbaInput
        placeholder="Apt/unit #"
        // onChange={(e) => onChange('unit', e.target.value)}
        value={params.address?.formattedAddress}
        className={styles['zorba-input']}
      /> */}
    </div>
  );
};
