import { Form, Input, InputNumber } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import styles from './ZorbaInput.module.scss';

const ZorbaInput = (props) => {
  const {
    disabled,
    label = '',
    loading,
    help,
    suffix,
    success,
    variant, 
    type,
    maxLength,
    fullWidth = false,
    symbol = '$',
    className = '',
    parserRegex,
    validateStatus,
    inputId,
    onChange,
    field,     
     minValue, 
     disableFormatter,
     maxValue
  } = props;
  const itemProps = _.pick(props, ['help', 'prefix', 'tooltip']);
  const inputProps = _.pick(props, [
    'placeholder',
    'value',
    'onChange',
    'prefix',
    'onBlur',
    'autoComplete',
    'disabled',
    'addonAfter',
    'addonBefore',
    'maxLength',
  ]);

  const getSuffix = () => {
    if (suffix) return suffix;
    if (success) return <i className="fas fa-check-circle" />;
    return loading ? <Spinner /> : null;
  };
  const handleOnChange = (newValue) => {
    if (!onChange) return;

    if (type === 'number' && !newValue) {
      onChange(null);
    } else {
      onChange(type === 'number' ? +newValue : newValue);
    }
  };
  // const onChange = (newValue) => {
  //   if (type === 'number' && !newValue) {
  //     props.onChange && props.onChange();
  //   } else if (props.onChange) {
  //     props.onChange(type === 'number' ? +newValue : newValue);
  //   }
  // };
  let inputComponent
  switch (type) {
    case "number":
      inputComponent = (
        <InputNumber
          {...inputProps}
          className={classNames(styles['zorba-input'], {
            [variant]: !!variant,
            [styles['full-width']]: fullWidth,
            [className]: !!className,
          })}
          suffix={getSuffix()}
          formatter={(value) =>
            !disableFormatter
              ? `${symbol && value ? `${symbol} ` : ''}${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ',',
                )
              : value
          }
          parser={(value) => value.replace(parserRegex || /\$\s?|(,*)/g, '')}

          onBlur={(newVal) => {
            const numVal = newVal.target.value.replace(
              parserRegex || /\$\s?|(,*)/g,
              '',
            );
            if (inputProps.onBlur) inputProps.onBlur(numVal);
            else onChange(numVal ? +numVal : null);
          }}
          {...(field || {})}
          autoComplete="off"
          min={minValue}
          max={maxValue}
        />
      )
    break;
    case "tel":
      inputComponent = (
        <InputNumber
          {...inputProps}
          // type="tel"
          type="text" style={{width: '100%'}}
          className={classNames(styles['zorba-input'], {
            [variant]: !!variant,
            [styles['full-width']]: fullWidth,
            [className]: !!className,
          })}
          suffix={getSuffix()}
          // formatter={(value) =>
          //   !disableFormatter
          //     ? `${symbol && value ? `${symbol} ` : ''}${value}`.replace(
          //         /\B(?=(\d{3})+(?!\d))/g,
          //         ',',
          //       )
          //     : value
          // }
          parser={(value) => value.replace(parserRegex || /\$\s?|(,*)/g, '')}

          onBlur={(newVal) => {
            const numVal = newVal.target.value.replace(
              parserRegex || /\$\s?|(,*)/g,
              '',
            );
            if (inputProps.onBlur) inputProps.onBlur(numVal);
            else onChange(numVal ? +numVal : null);
          }}
          {...(field || {})}
          autoComplete="off"
          min={minValue}
          max={maxValue}
        />
      )
    break;    
    default:    
    inputComponent = (
      <Input
        autoComplete="off"
        disabled={!!disabled}
        maxLength={maxLength}
        onFocus={(event) => {
          if (event.target.autocomplete) {
            event.target.autocomplete = 'whatever';
          }
          if (inputProps.onFocus) inputProps.onFocus();
        }}
        onBlur={() => { if (inputProps.onBlur) inputProps.onBlur()}}
        {...inputProps}
        type={type || 'text'}
        className={classNames(styles['zorba-input'], {
          [variant]: !!variant,
          [styles['full-width']]: fullWidth,
          [className]: !!className,
        })}
        suffix={getSuffix()}
        {...(field || {})}
      />
    );
    break;
      }
  return label ? (
    <Form.Item
      {...itemProps}
      label={label}
      className={classNames(styles['zorba-input-item'], {
        [styles['full-width']]: fullWidth,
      })}
      validateStatus={validateStatus || (help ? 'error' : 'success')}
    >
      {inputComponent}
    </Form.Item>
  ) : (
    inputComponent
  );
};

ZorbaInput.defaultProps = {
  disabled: false,
  label: '',
  loading: false,
  help: null,
  suffix: null,
  success: false,
  variant: null,
  type: 'text',
  maxLength: null,
  fullWidth: false,
  symbol: '$',
  className: '',
  parserRegex: null,
  validateStatus: null,
  placeholder: null,
  value: null,
  onChange: null,
  prefix: null,
  onBlur: null,
  autoComplete: null,
};

ZorbaInput.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'tel']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  autoComplete: PropTypes.string,
  symbol: PropTypes.string,
  help: PropTypes.string,
  variant: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validateStatus: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  parserRegex: PropTypes.instanceOf(RegExp),
};

export default ZorbaInput;
