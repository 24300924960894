export const isHomeownerMode = (pathname) => {
  if (!pathname) return true;
  return (
    pathname?.includes('seller-lead') || pathname?.includes('dashboard') ||
    pathname === '/' ||
    pathname.startsWith('/sell/') || pathname?.includes('faq') || pathname?.includes('about-us') || pathname?.includes('pm') || pathname?.includes('data')
  );
};

export const isHomeownerWizard = (pathname) => {
  if (!pathname) return true;

  return pathname?.startsWith('/seller-lead') || pathname?.startsWith('/dashboard');
};
