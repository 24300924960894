import styles from '../../SellerLeadWizard.module.scss';
import classNames from 'classnames';
import { ZorbaInput } from '../../../../components/forms';

export default (props) => {
  const { params, onChange } = props;
  return (
    <div className={classNames(styles['step'], styles['full-name'])}>
      <ZorbaInput
        placeholder="FIRST NAME"
        onChange={(e) => onChange('firstName', e.target.value)}
        value={params.firstName}
      />
      <ZorbaInput
        placeholder="LAST NAME"
        onChange={(e) => onChange('lastName', e.target.value)}
        value={params.lastName}
      />
    </div>
  );
};
