import { getWindow } from '@zorba-shared/client/Services/clientService';
// import { buildLoginUrl } from '@zorba-shared/core/Services/urlBuilderService';
import Link from 'next/link';
import React from 'react';
import { reportEvent } from '../../../../../services/analyticsService';
import { ZorbaButton } from '../../../../../components/forms';
import { VARIANT_BUTTON_LINK } from '../../../../../components/forms/ZorbaButton/constants';
import { HEADER_LOGIN_EXCLUDED_URLS } from './constants';
import styles from './LoginButton.module.scss';

const buildLoginUrl = () => 'https://partners.getzorba.com/login';
export default (props) => {
  const { userDetails, isAuthLoading } = props;

  // if (HEADER_LOGIN_EXCLUDED_URLS.includes(getWindow()?.location?.pathname))
  //   return null;
  const onClick = () => {
    // props.push("/login");
    reportEvent('auth', 'open', { source: 'header' });
  };

  if (isAuthLoading || userDetails) return null;
  return (
    <div className={styles['login-buttons']}>
      <Link href={buildLoginUrl(true)} passHref>
        <a>
          <ZorbaButton
            onClick={onClick}
            variant={VARIANT_BUTTON_LINK}
            className={styles['header-login-btn']}
          >
            <span>Login</span>
          </ZorbaButton>
        </a>
      </Link>

      <div className={styles['header-signup-btn']} onClick={onClick}>
        <Link href={buildLoginUrl(true)}>
          <a>
            <ZorbaButton>Get Started</ZorbaButton>
          </a>
        </Link>
      </div>
    </div>
  );
};
