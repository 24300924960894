import { DEFAULT_PARAMS } from '../../containers/SellerLeadWizard/constants';
import * as types from './types';

const initialState = {
  selectedAddress: {},
  params: {...DEFAULT_PARAMS},
  propertyMatches: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_SELECTED_ADDRESS:
    case types.SET_PARAMS:
      case types.SET_PROPERTY_MATCHES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
