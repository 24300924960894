import { createStore, applyMiddleware, combineReducers } from 'redux'
import {createWrapper, HYDRATE} from 'next-redux-wrapper';
// import combinedReducer from './reducers'
import { createRouterMiddleware, initialRouterState, routerReducer } from 'connected-next-router'
import Reducers from './reducers'
import Router from 'next/router'
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    ...Reducers,
    router: routerReducer
  });

// Using next-redux-wrapper's initStore
const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }
    // if (state.count) nextState.count = state.count; // preserve count value on client side navigation
    if (typeof window !== 'undefined' && state?.router) {
      // preserve router value on client side navigation
      nextState.router = state.router
    }
    return nextState
  } 
  else if (action.type === 'TICK') {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }
    return nextState;
  }
  else {
    return rootReducer(state, action)
  }
}
export const initStore = (context) => {
  const routerMiddleware = createRouterMiddleware();
  const middlewares = [routerMiddleware, thunk];

  const { asPath } = context.ctx || Router.router || {}
    let initialState
    if (asPath) {
      initialState = {
        router: initialRouterState(asPath)
      }
    }
    return createStore(reducer, initialState, applyMiddleware(...middlewares))
  }
  
  export const wrapper = createWrapper(initStore)