const BASE_URL_BY_ENV = {
  development: 'https://localhost:5001',
  staging: 'https://stg.api.getzorba.com',
  production: 'https://api.getzorba.com',
};

export const isDev = () =>
  ['development', null, undefined].includes(process.env.REACT_APP_HOST_ENV);

const getEnvName = () => process.env.REACT_APP_HOST_ENV || 'development';

export const BASE_URL = BASE_URL_BY_ENV[getEnvName()];
export const COOKIE_NAME_REDIRECT_URL = 'redirect_url';

export const isClientSide = () =>
  typeof window !== 'undefined' && window.document;

export const extendUrlSearchParams = () => {
  window.URLSearchParams =
    window.URLSearchParams ||
    function (searchString) {
      const self = this;
      self.searchString = searchString;
      self.get = function (name) {
        const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(
          self.searchString
        );
        if (results == null) {
          return null;
        }
        return decodeURI(results[1]) || 0;
      };
    };

  const envName = getEnvName();
  if (envName !== 'production' && document.title[0] !== '[') {
    document.title = `[${envName}] ${document.title}`;
  }
};

export const isMobile = () => {
  try {
    if (!navigator) return false;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
};

export const isSafari = () =>
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const isIE = () => /MSIE|Trident|Edg/.test(navigator.userAgent);

export const getQueryParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get(name);
  return myParam || undefined;
};

export const setCookie = (name, value, days, domain = '.getzorba.com') => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  let newCookie = `${name}=${value || ''}${expires}; path=/`;
  if (domain) {
    newCookie += `; domain=${domain}`;
  }
  document.cookie = newCookie;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const removeCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const getAndDeleteCookie = (name) => {
  const cookieValue = getCookie(name);
  cookieValue && removeCookie(name);

  return cookieValue;
};

export const setRedirectUrl = (redirectUrl) => {
  setCookie(COOKIE_NAME_REDIRECT_URL, redirectUrl, 1); // Limit it to 1 day
};

export const getRedirectUrl = (shouldRemoveCookie = true) => {
  const res = getCookie(COOKIE_NAME_REDIRECT_URL);
  shouldRemoveCookie && removeCookie(COOKIE_NAME_REDIRECT_URL);

  return res;
};

export function hideKeyboard() {
  // this set timeout needed for case when hideKeyborad
  // is called inside of 'onfocus' event handler
  setTimeout(() => {
    // creating temp field
    const field = document.createElement('input');
    field.setAttribute('type', 'text');
    // hiding temp field from peoples eyes
    // -webkit-user-modify is nessesary for Android 4.x
    field.setAttribute(
      'style',
      'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;'
    );
    document.body.appendChild(field);

    // adding onfocus event handler for out temp field
    field.onfocus = function () {
      // this timeout of 200ms is nessasary for Android 2.3.x
      setTimeout(() => {
        field.setAttribute('style', 'display:none;');
        setTimeout(() => {
          document.body.removeChild(field);
          document.body.focus();
        }, 14);
      }, 200);
    };
    // focusing it
    field.focus();
  }, 50);
}
