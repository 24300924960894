import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// import "./index.scss";
import styles from './Spinner.module.scss'
import classNames from 'classnames';

export default (props) => {
  const { className = "", fontSize = 24, style = {} } = props;
  const antIcon = <LoadingOutlined style={{ ...style, fontSize }} spin />;
  return (
    <div className={classNames(styles["spinner-wrapper"],styles[className])}>
      <Spin indicator={antIcon} />
    </div>
  );
};
